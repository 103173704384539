import React from 'react';
import styled from 'styled-components';
import theme from 'src/theme';

const config = {
  primary: {
    background: theme.colors.primaryColor[700],
    color: 'white',
  },
  secondary: {
    background: theme.colors.gray[200],
    color: theme.colors.gray[800],
  },
  alert: {
    background: theme.colors.red[700],
    color: 'white',
  },
  text: {
    background: 'transparent',
    color: theme.colors.gray[600],
  },
  disabled: {
    background: theme.colors.gray[300],
    color: theme.colors.gray[200],
  },
};

const ButtonContainer = styled.div`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
  width: ${({ width }) => (width ? width : '100%')};
  color: ${({ variant, disabled }) =>
    disabled ? config['disabled'].color : config[variant].color};
  background-color: ${({ variant, disabled }) =>
    disabled ? config['disabled'].background : config[variant].background};
  text-align: center;
  padding: 14px 0px;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Button = ({
  width,
  children,
  onClick,
  variant = 'primary',
  maxWidth,
  className,
  disabled,
}) => {
  return (
    <ButtonContainer
      className={className}
      width={width}
      variant={variant}
      disabled={disabled}
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick();
      }}
      maxWidth={maxWidth}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;
