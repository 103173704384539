import React from 'react';
import { t } from 'src/i18n/config';
import Popup from 'src/components/users/components/Popup';
import { getURLSearchParamByKey } from 'src/utils';
import { closeLiffWindow } from 'src/lib/liff';
import Button from 'src/components/users/components/Button';

const titlesKeys = {
  bind: t('dataAddedSuccessfully'),
  create: t('successUpdated'),
  delete: t('deleteSuccessTitle'),
};
const subTextKeys = {
  bind: t('bindingSuccess'),
  delete: t('deleteSuccessSubtext'),
};

const SuccessPopup = () => {
  const from = getURLSearchParamByKey('from');
  return (
    <Popup
      type="success"
      title={titlesKeys[from] || ''}
      subtext={subTextKeys[from] || ''}
    >
      <Button maxWidth="343px" onClick={closeLiffWindow} variant="secondary">
        {t('backToLine')}
      </Button>
    </Popup>
  );
};

export default SuccessPopup;
