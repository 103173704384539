import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import { t } from 'src/i18n/config';
import SuccessPopup from 'src/components/users/components/Success';

const Success = () => {
  return (
    <Layout>
      <Helmet>
        <title>{t('basicInformationSetup')}</title>
      </Helmet>
      <SuccessPopup />
    </Layout>
  );
};

export default Success;
